<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<mimdg-modals
			:modelName="modelName"
		></mimdg-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import mimdgActions from './MimdgActions';
import mimdgModals from './MimdgModals.vue';

export default {
	name: 'mimdg-grid',
	components: {
		'mimdg-modals': mimdgModals
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'mimdg',
			actions: mimdgActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
